//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-208:_420,_376,_996,_140,_186,_424,_192,_220;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-208')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-208', "_420,_376,_996,_140,_186,_424,_192,_220");
        }
      }catch (ex) {
        console.error(ex);
      }